<!--<div class='Settle'></div>-->
<!--    门店分润报表-->
<template>
    <div class="Settle" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: left"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <ef-dept-type-and-dept-select @selectDeptType="setDeptType" @selectDept="setDept" />
                    <el-col :span="8">
                        <el-form-item label="审核状态">
                            <el-select placeholder="请选择" v-model="form.reviewStatus">
                                <el-option
                                    v-for="e in meta.reviewEnums"
                                    :label="e.label"
                                    :value="e.code"
                                    :key="e.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.report.settle.open')">
                查询
            </el-button>
            <el-button
                type="primary"
                @click="handleAudit"
                size="small"
                v-if="hasPrivilege('menu.report.settle.review')"
            >
                审核
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.report.settle.create')"
            >
                新建
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.report.settle.export')"
            >
                导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="settleTable"
                ref="settleTable"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="450"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="50" type="index" fixed="left" />
                <el-table-column label="结算月份" width="70" prop="month" key="month" v-if="showColumn('month')">
                    <template slot-scope="scope">
                        <span> {{ scope.row.year }}-{{ scope.row.month }} </span>
                    </template>
                </el-table-column>
                <el-table-column label="机构" width="140" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column
                    label="审核状态"
                    width="96"
                    prop="reviewStatus"
                    key="reviewStatus"
                    v-if="showColumn('reviewStatus')"
                >
                    <template slot-scope="scope">
                        <span>
                            {{ scope.row.reviewStatus | reviewStatus }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="期初日期"
                    width="120"
                    prop="startDate"
                    align="right"
                    v-if="showColumn('startDate')"
                />
                <el-table-column label="期末日期" width="120" prop="endDate" v-if="showColumn('endDate')" />
                <el-table-column
                    align="right"
                    label="商品基本信息类别数量"
                    width="160"
                    prop="skuCountSummary"
                    v-if="showColumn('skuCountSummary')"
                />
                <el-table-column
                    align="right"
                    label="期初数量汇总"
                    width="120"
                    prop="startCountSummary"
                    v-if="showColumn('startCountSummary')"
                />
                <el-table-column
                    align="right"
                    label="原始期初成本金额汇总"
                    width="180"
                    prop="originalStartCostSummary"
                    key="originalStartCostSummary"
                    v-if="showColumn('originalStartCostSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.originalStartCostSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="原始期初成本差异汇总"
                    width="180"
                    prop="originalStartCostDiffSummary"
                    key="originalStartCostDiffSummary"
                    v-if="showColumn('originalStartCostDiffSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.originalStartCostDiffSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="计划成本调整差异汇总"
                    width="180"
                    prop="planCostDiffSummary"
                    key="planCostDiffSummary"
                    v-if="showColumn('planCostDiffSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.planCostDiffSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="期初成本金额汇总"
                    width="160"
                    prop="startCostSummary"
                    key="startCostSummary"
                    v-if="showColumn('startCostSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.startCostSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="期初成本差异汇总"
                    width="160"
                    prop="startCostDiffSummary"
                    key="startCostDiffSummary"
                    v-if="showColumn('startCostDiffSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.startCostDiffSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="采购数量汇总"
                    width="120"
                    prop="purchaseCountSummary"
                    v-if="showColumn('purchaseCountSummary')"
                />
                <el-table-column
                    align="right"
                    label="采购成本金额汇总"
                    width="160"
                    prop="purchaseCostSummary"
                    key="purchaseCostSummary"
                    v-if="showColumn('purchaseCostSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.purchaseCostSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="采购金额汇总"
                    width="120"
                    prop="purchasePriceSummary"
                    key="purchasePriceSummary"
                    v-if="showColumn('purchasePriceSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.purchasePriceSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="采购成本差异汇总"
                    width="160"
                    prop="purchaseCostDiffSummary"
                    key="purchaseCostDiffSummary"
                    v-if="showColumn('purchaseCostDiffSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.purchaseCostDiffSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="采购退货数量汇总"
                    width="160"
                    prop="purchaseRefundCountSummary"
                    v-if="showColumn('purchaseRefundCountSummary')"
                />
                <el-table-column
                    align="right"
                    label="采购退货成本金额汇总"
                    width="180"
                    prop="purchaseRefundCostSummary"
                    key="purchaseRefundCostSummary"
                    v-if="showColumn('purchaseRefundCostSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.purchaseRefundCostSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="采购退货金额汇总"
                    width="160"
                    prop="purchaseRefundPriceSummary"
                    key="purchaseRefundPriceSummary"
                    v-if="showColumn('purchaseRefundPriceSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.purchaseRefundPriceSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="采购退货成本差异汇总"
                    width="180"
                    prop="purchaseRefundCostDiffSummary"
                    key="purchaseRefundCostDiffSummary"
                    v-if="showColumn('purchaseRefundCostDiffSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.purchaseRefundCostDiffSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="调拨入库数量汇总"
                    width="160"
                    prop="allocateInCountSummary"
                    v-if="showColumn('allocateInCountSummary')"
                />
                <el-table-column
                    align="right"
                    label="调拨入库成本金额汇总"
                    width="180"
                    prop="allocateInCostSummary"
                    key="allocateInCostSummary"
                    v-if="showColumn('allocateInCostSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.allocateInCostSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="调拨入库金额汇总"
                    width="160"
                    prop="allocateInPriceSummary"
                    key="allocateInPriceSummary"
                    v-if="showColumn('allocateInPriceSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.allocateInPriceSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="调拨入库成本差异汇总"
                    width="180"
                    prop="allocateInCostDiffSummary"
                    key="allocateInCostDiffSummary"
                    v-if="showColumn('allocateInCostDiffSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.allocateInCostDiffSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="报损数量汇总"
                    width="120"
                    prop="lossCountSummary"
                    v-if="showColumn('lossCountSummary')"
                />
                <el-table-column
                    align="right"
                    label="报损成本金额汇总"
                    width="160"
                    prop="lossCostSummary"
                    key="lossCostSummary"
                    v-if="showColumn('lossCostSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.lossCostSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="报溢数量汇总"
                    width="120"
                    prop="surplusCountSummary"
                    v-if="showColumn('surplusCountSummary')"
                />
                <el-table-column
                    align="right"
                    label="报溢成本金额汇总"
                    width="160"
                    prop="surplusCostSummary"
                    key="surplusCostSummary"
                    v-if="showColumn('surplusCostSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.surplusCostSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="销售数量汇总"
                    width="120"
                    prop="saleCountSummary"
                    v-if="showColumn('saleCountSummary')"
                />
                <el-table-column
                    align="right"
                    label="销售成本金额汇总"
                    width="160"
                    prop="saleCostSummary"
                    key="saleCostSummary"
                    v-if="showColumn('saleCostSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.saleCostSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="销售金额汇总"
                    width="120"
                    prop="salePriceSummary"
                    key="salePriceSummary"
                    v-if="showColumn('salePriceSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.salePriceSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="销售退货数量汇总"
                    width="160"
                    prop="saleRefundCountSummary"
                    v-if="showColumn('saleRefundCountSummary')"
                />
                <el-table-column
                    align="right"
                    label="销售退货成本金额汇总"
                    width="160"
                    prop="saleRefundCostSummary"
                    key="saleRefundCostSummary"
                    v-if="showColumn('saleRefundCostSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.saleRefundCostSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="销售退货金额汇总"
                    width="160"
                    prop="saleRefundPriceSummary"
                    key="saleRefundPriceSummary"
                    v-if="showColumn('saleRefundPriceSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.saleRefundPriceSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="调拨出库数量汇总"
                    width="160"
                    prop="allocateOutCountSummary"
                    v-if="showColumn('allocateOutCountSummary')"
                />
                <el-table-column
                    align="right"
                    label="调拨出库成本金额汇总"
                    width="180"
                    prop="allocateOutCostSummary"
                    key="allocateOutCostSummary"
                    v-if="showColumn('allocateOutCostSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.allocateOutCostSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="调拨出库金额汇总"
                    width="160"
                    prop="allocateOutPriceSummary"
                    key="allocateOutPriceSummary"
                    v-if="showColumn('allocateOutPriceSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.allocateOutPriceSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="内购数量汇总"
                    width="160"
                    prop="receiveCountSummary"
                    v-if="showColumn('receiveCountSummary')"
                />
                <el-table-column
                    align="right"
                    label="内购成本金额汇总"
                    width="160"
                    prop="receiveCostSummary"
                    key="receiveCostSummary"
                    v-if="showColumn('receiveCostSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.receiveCostSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="内购金额汇总"
                    width="160"
                    prop="receivePriceSummary"
                    key="receivePriceSummary"
                    v-if="showColumn('receivePriceSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.receivePriceSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="系统初始化入库数量汇总"
                    width="160"
                    prop="sysPurchaseCountSummary"
                    v-if="showColumn('sysPurchaseCountSummary')"
                />
                <el-table-column
                    align="right"
                    label="系统初始化入库成本金额汇总"
                    width="180"
                    prop="sysPurchaseCostSummary"
                    key="sysPurchaseCostSummary"
                    v-if="showColumn('sysPurchaseCostSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.sysPurchaseCostSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="系统初始化入库金额汇总"
                    width="160"
                    prop="sysPurchasePriceSummary"
                    key="sysPurchasePriceSummary"
                    v-if="showColumn('sysPurchasePriceSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.sysPurchasePriceSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="系统初始化入库成本差异汇总"
                    width="180"
                    prop="sysPurchaseCostDiffSummary"
                    key="sysPurchaseCostDiffSummary"
                    v-if="showColumn('sysPurchaseCostDiffSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.sysPurchaseCostDiffSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="系统初始化出库数量汇总"
                    width="160"
                    prop="sysPurchaseOutCountSummary"
                    v-if="showColumn('sysPurchaseOutCountSummary')"
                />
                <el-table-column
                    align="right"
                    label="系统初始化出库成本金额汇总"
                    width="180"
                    prop="sysPurchaseOutCostSummary"
                    key="sysPurchaseOutCostSummary"
                    v-if="showColumn('sysPurchaseOutCostSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.sysPurchaseOutCostSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="系统初始化出库金额汇总"
                    width="160"
                    prop="sysPurchaseOutPriceSummary"
                    key="sysPurchaseOutPriceSummary"
                    v-if="showColumn('sysPurchaseOutPriceSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.sysPurchaseOutPriceSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="系统初始化出库成本差异汇总"
                    width="180"
                    prop="sysPurchaseOutCostDiffSummary"
                    key="sysPurchaseOutCostDiffSummary"
                    v-if="showColumn('sysPurchaseOutCostDiffSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.sysPurchaseOutCostDiffSummary | mFour }}</span>
                    </template>
                </el-table-column>

                <!-- <el-table-column
                    align="right"
                    label="电商销售数量汇总"
                    width="180"
                    prop="onlineShoppingOrderCountSummary"
                    key="onlineShoppingOrderCountSummary"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.onlineShoppingOrderCountSummary }}</span>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column
                    align="right"
                    label="电商销售成本金额汇总"
                    width="180"
                    prop="onlineShoppingOrderCostSummary"
                    key="onlineShoppingOrderCostSummary"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.onlineShoppingOrderCostSummary | mFour }}</span>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column
                    align="right"
                    label="电商销售金额汇总"
                    width="180"
                    prop="onlineShoppingOrderPriceSummary"
                    key="onlineShoppingOrderPriceSummary"
                    v-if="showColumn('onlineShoppingOrderPriceSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.onlineShoppingOrderPriceSummary | mFour }}</span>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column
                    align="right"
                    label="电商退款数量汇总"
                    width="180"
                    prop="onlineShoppingRefundCountSummary"
                    key="onlineShoppingRefundCountSummary"
                    v-if="showColumn('onlineShoppingRefundCountSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.onlineShoppingRefundCountSummary }}</span>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column
                    align="right"
                    label="电商退款成本金额汇总"
                    width="180"
                    prop="onlineShoppingRefundCostSummary"
                    key="onlineShoppingRefundCostSummary"
                    v-if="showColumn('onlineShoppingRefundCostSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.onlineShoppingRefundCostSummary | mFour }}</span>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column
                    align="right"
                    label="电商退款金额汇总"
                    width="180"
                    prop="onlineShoppingRefundPriceSummary"
                    key="onlineShoppingRefundPriceSummary"
                    v-if="showColumn('onlineShoppingRefundPriceSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.onlineShoppingRefundPriceSummary | mFour }}</span>
                    </template>
                </el-table-column> -->

                <el-table-column
                    align="right"
                    label="进项数量汇总"
                    width="160"
                    prop="inCountSummary"
                    v-if="showColumn('inCountSummary')"
                />
                <el-table-column
                    align="right"
                    label="进项成本金额汇总"
                    width="160"
                    prop="inCostSummary"
                    key="inCostSummary"
                    v-if="showColumn('inCostSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.inCostSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="进项金额汇总"
                    width="160"
                    prop="inPriceSummary"
                    key="inPriceSummary"
                    v-if="showColumn('inPriceSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.inPriceSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="进项成本差异汇总"
                    width="160"
                    prop="inCostDiffSummary"
                    key="inCostDiffSummary"
                    v-if="showColumn('inCostDiffSummary')"
                >
                    <template slot="header">
                        <el-tooltip
                            effect="light"
                            content="进项成本差异汇总 = 采购成本差异 - 采购退货成本差异 + 调拨入库成本差异 + 系统初始化入库成本差异汇总 - 系统初始化出库成本差异汇总"
                            placement="top"
                        >
                            <span>进项成本差异汇总 <i class="el-icon-info"></i></span>
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">
                        <!--  v-if="scope.row.inCostDiffSummary"-->
                        <span
                            class="greenClass"
                            style="color: #19c989; cursor: pointer"
                            @click="inCostDiffSummaryMore(scope.row)"
                            >{{ scope.row.inCostDiffSummary | mFour }}</span
                        >
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="出项数量汇总"
                    width="120"
                    prop="outCountSummary"
                    v-if="showColumn('outCountSummary')"
                />
                <el-table-column
                    align="right"
                    label="出项成本金额汇总"
                    width="160"
                    prop="outCostSummary"
                    key="outCostSummary"
                    v-if="showColumn('outCostSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.outCostSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="出项金额汇总"
                    width="120"
                    prop="outPriceSummary"
                    key="outPriceSummary"
                    v-if="showColumn('outPriceSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.outPriceSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="结存数量汇总"
                    width="120"
                    prop="endCountSummary"
                    v-if="showColumn('endCountSummary')"
                />
                <el-table-column
                    align="right"
                    label="结存成本金额汇总"
                    width="160"
                    prop="endCostSummary"
                    key="endCostSummary"
                    v-if="showColumn('endCostSummary')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.endCostSummary | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="right"
                    label="结存成本差异汇总"
                    width="160"
                    prop="endCostDiff"
                    key="endCostDiff"
                    v-if="showColumn('endCostDiff')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.endCostDiff | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建人" width="140" prop="creator" v-if="showColumn('creator')" />
                <el-table-column
                    align="right"
                    label="创建时间"
                    width="160"
                    prop="createTime"
                    v-if="showColumn('createTime')"
                />
                <el-table-column
                    label="操作"
                    min-width="150"
                    header-align="center"
                    prop="operate"
                    key="operate"
                    v-if="showColumn('operate')"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="handleDetail(scope.row)"
                            v-if="hasPrivilege('menu.report.settle.open')"
                        >
                            查看
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            v-if="hasPrivilege('menu.report.settle.delete')"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <ef-review-biz ref="review" baseUrl="/report/monthSettle" @completed="handleQuery" />

        <!--  进项成本差异表格  -->
        <el-dialog
            title="进项成本差异汇总"
            :visible.sync="inCostDiffSummaryMoreType"
            width="55%"
            top="15%"
            @close="closeInCostDiffSummaryMore"
        >
            <el-table :data="inCostDiffSummaryMoreFrom">
                <el-table-column property="purchaseCostDiffSummary" label="采购成本差异" width="150">
                    <template slot-scope="scope">
                        <span>{{ scope.row.purchaseCostDiffSummary | mFourOrZero }} </span>
                    </template>
                </el-table-column>
                <el-table-column property="purchaseRefundCostDiffSummary" label="采购退货成本差异" width="220">
                    <template slot-scope="scope">
                        <span>{{ scope.row.purchaseRefundCostDiffSummary | mFourOrZero }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="allocateInCostDiffSummary" label="调拨入库成本差异" width="220">
                    <template slot-scope="scope">
                        <span>{{ scope.row.allocateInCostDiffSummary | mFourOrZero }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="sysPurchaseCostDiffSummary" label="系统初始化入库成本差异汇总" width="220">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sysPurchaseCostDiffSummary | mFourOrZero }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="sysPurchaseOutCostDiffSummary" label="系统初始化出库成本差异汇总">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sysPurchaseOutCostDiffSummary | mFourOrZero }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="closeInCostDiffSummaryMore">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import EfReviewBiz from 'components/EfReviewBiz';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
import Decimal from 'decimal';

export default {
    name: 'MonthSettle',
    components: { EfEndDate, EfStartDate, EfDeptTypeAndDeptSelect, EfReviewBiz, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                deptCode: '',
                deptType: '',
                reviewStatus: '',
                startTime: '',
                endTime: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                page: '/report/monthSettle/page',
                delete: '/report/monthSettle/delete',
            },
            current: {
                typeIdx: 3,
            },
            meta: {
                types: [],
                reviewEnums: Util.reviewStatusEnum(true),
                showReview: false,
            },
            tableRef: 'settleTable',
            inCostDiffSummaryMoreType: false,
            inCostDiffSummaryMoreFrom: [],
        };
    },
    mounted() {
        this.handleQuery();
    },
    filters: {
        mFourOrZero(money) {
            if (typeof money != 'undefined') {
                if (money == 0) {
                    return '0.000';
                }
                return Decimal(money).div(10000).toNumber().toFixed(4);
            }
            return '';
        },
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        //进项成本差异汇总详情
        inCostDiffSummaryMore(data) {
            this.inCostDiffSummaryMoreType = true;
            this.inCostDiffSummaryMoreFrom.push(data);
        },
        closeInCostDiffSummaryMore() {
            this.inCostDiffSummaryMoreType = false;
            this.inCostDiffSummaryMoreFrom = [];
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleCreate() {
            const _this = this;
            if (!_this.form.deptCode) {
                this.$message.error('请选择要生成报表的机构');
                return;
            }
            UrlUtils.PostRemote(
                _this,
                '/report/monthSettle/createSettle',
                {
                    deptCode: _this.form.deptCode,
                    message: '是否创建月结单据?页面数据较多,创建时间长,请耐心等待!',
                },
                null,
                () => {
                    this.$message.success('结算单据创建成功');
                    this.handleQuery();
                },
                (msg) => {
                    this.$message.error(msg);
                    this.handleQuery();
                }
            );
        },
        handleExport() {
            if (this.$refs['settleTable'].selection.length !== 1) {
                this.$message.error('请选择要一条导出的单据');
                return;
            }
            const code = this.$refs['settleTable'].selection[0].code;
            UrlUtils.Export(this, '月结报表', '/report/monthSettle/exportExtend', { code: code });
        },
        handlePrint() {
            this.printHTML('settleTable', this.$route.meta.title);
        },
        handleAudit() {
            if (this.$refs.settleTable.selection.length != 1) {
                this.$message.error('一次只能审核一条数据');
                return;
            }
            this.$refs.review.open(this.$refs.settleTable.selection);
        },
        handleSelectType() {
            const _this = this;
            _this.form.deptType = _this.meta.types[_this.current.typeIdx].type;
        },
        handleDetail(row) {
            Util.nameJump(this, 'menu.report.settle.detail', ['报表管理', '月结报表', '月结详情'], {
                form: row,
                code: row.code,
            });
        },
        handleDelete(row) {
            if (row.reviewStatus) {
                this.$alert('非待审核业务不可删除');
                return;
            }
            UrlUtils.DeleteRemote(this, this.url.delete, row.code);
        },
        mFour: Util.mFour,
        mCount: Util.mCount,
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
        },
    },
};
</script>

<style scoped>
.Settle .el-form-item {
    margin-bottom: 0;
}
</style>
<style>
.greenClass :hover {
    cursor: pointer !important;
}
</style>
